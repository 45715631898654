import React from "react"
import { RadioGroup } from "@headlessui/react"
import { GatsbyImage } from "gatsby-plugin-image"

function RadioGroupSelect({
  data,
  items,
  columns = 3,
  a,
  b,
  selected,
  setSelected,
}) {
  return (
    <div className="mx-auto w-full py-4">
      <RadioGroup value={selected} by="id" onChange={setSelected}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div
          className={`grid gap-4 ${
            columns === 4 ? "grid-cols-2 sm:grid-cols-4" : "grid-cols-3"
          }`}
        >
          {items.map(plan => (
            <RadioGroup.Option
              key={plan.name}
              value={plan}
              className={({ active, checked }) =>
                `${
                  active
                    ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-indigo-300"
                    : ""
                }
                  ${
                    checked
                      ? "bg-indigo-300 text-white ring-indigo-700"
                      : "bg-white ring-transparent"
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md ring-2 hover:bg-indigo-100 focus:outline-none`
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label as="div" className="sr-only">
                          test
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={`inline ${
                            checked ? "text-indigo-100" : "text-gray-500"
                          }`}
                        >
                          <div>
                            {data.allFile.edges.map((item, index) => {
                              if (plan.name === item.node.name) {
                                return (
                                  <div key={index} className="relative">
                                    <GatsbyImage
                                      image={
                                        item.node.childImageSharp
                                          .gatsbyImageData
                                      }
                                      alt=""
                                    />
                                    {b ? (
                                      <div
                                        className="absolute top-32 left-0 my-auto w-16 rounded-md border border-gray-300 bg-white
                                    px-2 py-1 text-center font-mono text-lg text-gray-700"
                                      >
                                        {b}
                                      </div>
                                    ) : null}
                                    {a ? (
                                      <div
                                        className="absolute bottom-0 left-24 my-auto w-16 rounded-md border border-gray-300 bg-white
                                    px-2 py-1 text-center font-mono text-lg text-gray-700"
                                      >
                                        {a}
                                      </div>
                                    ) : null}
                                  </div>
                                )
                              }
                              return null
                            })}
                          </div>
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {checked && (
                      <div className="shrink-0 text-white">
                        <CheckIcon className="h-6 w-6" />
                      </div>
                    )}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="1" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RadioGroupSelect
