import React from "react"
import { graphql } from "gatsby"

import PForm from "../components/Forms/PForm"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PPage = ({ data }) => (
  <Layout>
    <SEO title="Реечные перегородки" />
    <PForm data={data} />
  </Layout>
)

export const query = graphql`
  query PPageQuery {
    allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(width: 240, placeholder: BLURRED, formats: PNG)
          }
        }
      }
    }
  }
`

export default PPage
