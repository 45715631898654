import React from "react"

import InputWrapper from "../UI/InputWrapper"
import InputWithInlineLeading from "../UI/InputWithInlineLeading"

function Dimensions({ a, b, h, n, setA, setB, setH, setN }) {
  return (
    <div className="flex flex-wrap justify-between space-x-0 space-y-1 sm:justify-start md:space-x-8">
      <InputWrapper label="Сечение" className="justify-between">
        <InputWithInlineLeading
          value={a}
          setValue={setA}
          label="Высота"
          pre="a"
          post="мм"
          name="aSize"
          id="aSize"
          max="600"
        />
        <span>x</span>
        <InputWithInlineLeading
          value={b}
          setValue={setB}
          label="Ширина"
          pre="b"
          post="мм"
          name="bSize"
          id="bSize"
          max="600"
        />
      </InputWrapper>

      <InputWrapper label="Длина">
        <InputWithInlineLeading
          value={h}
          setValue={setH}
          label="Длина"
          pre=""
          post="мм"
          name="hSize"
          id="hSize"
          max="3600"
        />
      </InputWrapper>

      <InputWrapper label="Кол-во">
        <InputWithInlineLeading
          value={n}
          setValue={setN}
          label="Количество"
          pre=""
          post="шт"
          name="nSize"
          id="nSize"
          max="10000"
        />
      </InputWrapper>
    </div>
  )
}

export default Dimensions
