import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useCart } from "react-use-cart"

import InputWithHiddenLabel from "../UI/InputWithHiddenLabel"
import ListboxSelect from "../UI/ListboxSelect"
import RadioGroupSelect from "../UI/RadioGroupSelect"

import Textarea from "../UI/Textarea"
import InputWrapper from "../UI/InputWrapper"

import AddButton from "../UI/AddButton"

import { radioP, coversData, colorsData } from "./data"
import Dimensions from "./Dimensions"
import Header from "./Header"
import Wrapper from "./Wrapper"

import Alert from "../UI/Alert"
import Notification from "../UI/Notification"

function PForm({ data }) {
  const [id, setId] = useState(uuidv4())

  const [dNum, setDNum] = useState("")

  const [a, setA] = useState("")
  const [b, setB] = useState("")
  const [h, setH] = useState("")
  const [n, setN] = useState("")

  const [cover, setCover] = useState(coversData[0])
  const [color, setColor] = useState(colorsData[0])
  const [colorID, setColorID] = useState("")

  const [comment, setComment] = useState("")
  const [selected, setSelected] = useState(radioP[0])

  const { addItem } = useCart()

  const [isAlertVisible, setAlertVisible] = useState(false)
  const [isNotificationVisible, setNotificationVisible] = useState(false)

  const emptyForm = () => {
    setId(uuidv4())

    setDNum("")
    setA("")
    setB("")
    setH("")
    setN("")

    setCover(coversData[0])
    setColor(colorsData[0])
    setColorID("")

    setComment("")
    setSelected(radioP[0])
  }

  const handleClick = () => {
    if (dNum === "" || a === "" || b === "" || h === "" || n === "") {
      setAlertVisible(true)
      setNotificationVisible(false)
    } else {
      window.scrollTo(0, 0)

      setAlertVisible(false)
      setNotificationVisible(true)

      addItem(
        {
          id,
          name: "Реечные перегородки",
          dNum,
          a,
          b,
          h,
          cover,
          color,
          colorID,
          selected,
          comment,
          price: 1,
        },
        n
      )

      emptyForm()
    }
  }

  return (
    <div className="space-y-2">
      <Wrapper>
        <Header title="Реечные перегородки">
          <InputWithHiddenLabel
            value={dNum}
            setValue={setDNum}
            label="№ договора"
            type="text"
            name="numD"
            id="numD"
            placeholder="№ договора"
          />
        </Header>
        <Dimensions
          a={a}
          b={b}
          h={h}
          n={n}
          setA={setA}
          setB={setB}
          setH={setH}
          setN={setN}
        />

        <div className="grid grid-cols-1 justify-start space-x-0 border-b border-gray-300 pb-4 sm:flex sm:flex-wrap md:space-x-8">
          <InputWrapper label="Покрытие" className="justify-between">
            <ListboxSelect
              data={coversData}
              value={cover}
              onChange={setCover}
            />
          </InputWrapper>

          <InputWrapper label="Цвет" className="justify-between">
            <ListboxSelect
              data={colorsData}
              value={color}
              onChange={setColor}
            />
          </InputWrapper>

          <div className="pt-1">
            <InputWithHiddenLabel
              value={colorID}
              setValue={setColorID}
              label="№ цвета"
              type="text"
              name="numС"
              id="numС"
              placeholder="№ цвета"
            />
          </div>
        </div>

        <div className="">
          <h3 className="pt-2 text-base sm:text-lg">Крепление</h3>
          <div className="grid grid-cols-3">
            <div className="text-sm sm:text-base">Скрытое</div>
            <div className="text-sm sm:text-base">Планка</div>
            <div className="text-sm sm:text-base" />
          </div>
          <RadioGroupSelect
            selected={selected}
            setSelected={setSelected}
            data={data}
            items={radioP}
          />
        </div>

        <div className="pb-4">
          <Textarea value={comment} setValue={setComment} />
        </div>

        <ul className="py-2 font-mono text-xs">
          <li>№ договора: {dNum}</li>
          <li>
            Сечение: {a} мм x {b} мм, Длина: {h} мм, Количество: {n} шт.,
            Покрытие: {cover.name}
          </li>
          <li>
            Цвет: {color.name}, № цвета: {colorID}
          </li>
          <li>Крепление: {selected.label}</li>
          <li>Комментарии: {comment}</li>
        </ul>
      </Wrapper>

      <Alert isVisible={isAlertVisible} dNum={dNum} a={a} b={b} h={h} n={n} />
      <AddButton onClick={handleClick} />
      <Notification
        isVisible={isNotificationVisible}
        setVisible={setNotificationVisible}
      />
    </div>
  )
}

export default PForm
